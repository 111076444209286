import React from "react";
import loadable from "@loadable/component";
import { devOpsFAQList } from "../components/DevOpsEngineering/components/FAQSection/FAQSection";
import { graphql } from "gatsby";

const Layout = loadable(() => import("@common/Layout/Layout"));
const DevOpsEngineering = loadable(() =>
    import("@components/DevOpsEngineering/DevOpsEngineering")
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const DevOpsEngineeringPage = ({ path, data }) => (
    <>
        <SEO
            title="Dedicated DevOps Services for Your Software Development Project"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Are you looking for DevOps services? Choose the Impressit dedicated DevOps specialists to build secure and reliable infrastructure for your project."
            path={path}
            faqSchema={devOpsFAQList}
        />
        <Layout path={path}>
            <DevOpsEngineering />
        </Layout>
    </>
);

export default DevOpsEngineeringPage;

export const devopsPageQuery = graphql`
    query devopsPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
